import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import {
    Title,
    ToggleButton,
    Input,
    EditorBox,
    Button,
    ConfirmModal,
    DropDown,
} from "@src/components";
import { Common } from "@src/styles/Common";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    CreateNoticeType,
    UpdateNoticeType,
    FormNoticeType,
} from "@src/types/NoticeType";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useNoticeFetchData, initParams } from "@src/hooks/queries/useNotice";
import {
    useNoticeDetailData,
    useCreateNotice,
    useUpdateNotice,
} from "@src/hooks/queries/useNoticeDetail";
import {
    isNotEmpty,
    isEditorEmpty,
    ConvertHtmlToString,
} from "@src/utils/textUtil";
import { validChecker } from "@src/utils/validationCheck";
import styled from "@emotion/styled";

interface PropsState {
    value: FormNoticeType;
}

export default function NoticeForm({ isUpdate = false }) {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initData, setInitData] = useState<FormNoticeType>({
        title: "",
        contents: "",
        isOpen: false,
        category: "app",
        displayOrder: 1000,
        edit: false,
        view: false,
    });
    const [updateData, setUpdateData] = useState<FormNoticeType>(initData);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>("");
    // const {
    //     isLoading,
    //     data: noticeData,
    //     isError,
    //     refetch: detailRefetch,
    // } = useNoticeDetailData(Number(id), isUpdate);
    const { refetch: listRefetch } = useNoticeFetchData(initParams);
    useEffect(() => {
        setUpdateData(initData);
    }, [initData]);

    useEffect(() => {
        if (location.state) {
            const { value } = location.state as PropsState;
            setInitData(value);
            value.category === "app-home" && setIsApphome(true);
        } else {
            setUpdateData(initData);
        }
    }, []);

    const [edit, setEdit] = useState<boolean>(initData.edit ?? true);
    useEffect(() => {
        //빈값 체크해서 버튼 활성화
        if (updateData.edit) {
            if (
                isNotEmpty(updateData.title) &&
                isEditorEmpty(updateData.contents)
            ) {
                setPageErrorMessage("");
                setEdit(true);
            } else {
                setEdit(false);
            }
        }
    }, [updateData]);

    const [isModalShow, setIsModalShow] = useState(false);
    const handleClick = () => {
        setIsModalShow((prev) => !prev);
    };

    const { mutate: createMutate } = useCreateNotice();
    const { mutate: updateMutate } = useUpdateNotice(id);

    const handleCreateSubmit = () => {
        dispatch(startLoading());
        if (updateData) {
            const params: CreateNoticeType = {
                title: updateData.title,
                contents: updateData.contents,
                isOpen: updateData.isOpen,
                category: updateData.category,
                displayOrder: updateData.displayOrder,
            };
            createMutate(params, {
                onSuccess: () => {
                    navigate("/help/notice");
                },
                onError: (error) => {
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    listRefetch();
                    dispatch(endLoading());
                    setIsModalShow((prev) => !prev);
                },
            });
        }
    };
    const handleUpdateSubmit = () => {
        setUpdateData({
            ...updateData,
        });
        dispatch(startLoading());
        if (updateData) {
            const params: UpdateNoticeType = {
                title: updateData.title,
                contents: updateData.contents,
                isOpen: updateData.isOpen,
                category: updateData.category,
                displayOrder: updateData.displayOrder,
            };
            updateMutate(params, {
                onSuccess: () => {
                    // detailRefetch();
                    navigate("/help/notice");
                },
                onError: (error) => {
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    listRefetch();
                    dispatch(endLoading());
                    setIsModalShow((prev) => !prev);
                },
            });
        }
    };
    const handleCancel = () => {
        setIsModalShow((prev) => !prev);
    };

    const [isApphome, setIsApphome] = useState<boolean>(false);

    useEffect(() => {
        if (updateData.category !== "app-home") {
            setIsApphome(false);
            setUpdateData({
                ...updateData,
                ...{ displayOrder: 1000, edit: true },
            });
        } else {
            setIsApphome(true);
        }
    }, [updateData.category]);

    return (
        <StyledFormDiv>
            <Title title={isUpdate ? "공지 수정" : "공지 등록"} />
            <ul>
                <li>
                    <span>노출 여부</span>
                    <ToggleButton
                        name="노출 여부"
                        value={initData.isOpen ?? false}
                        handleToggle={(_, checked) => {
                            setUpdateData({
                                ...updateData,
                                ...{ isOpen: checked, edit: true },
                            });
                        }}
                    />
                </li>
                <li>
                    <span>구분</span>
                    <div>
                        <DropDown
                            data={[
                                {
                                    id: 1,
                                    key: "app",
                                    value: "공지 - 일반",
                                },
                                {
                                    id: 2,
                                    key: "app-home",
                                    value: "공지 - 홈노출",
                                },
                                {
                                    id: 3,
                                    key: "homepage",
                                    value: "공지 - 랜딩",
                                },
                            ]}
                            defaultValue={initData.category}
                            border={false}
                            handleChange={(e: any, text, value) => {
                                if (value) {
                                    setUpdateData({
                                        ...updateData,
                                        ...{ category: value, edit: true },
                                    });
                                }
                            }}
                        />
                    </div>
                </li>
                <li>
                    <span>노출 순서</span>
                    <div>
                        <DropDown
                            data={[
                                {
                                    id: 1,
                                    key: 1000,
                                    value: "1000",
                                },
                                {
                                    id: 2,
                                    key: 5,
                                    value: "5",
                                },
                                {
                                    id: 3,
                                    key: 4,
                                    value: "4",
                                },
                                {
                                    id: 4,
                                    key: 3,
                                    value: "3",
                                },
                                {
                                    id: 5,
                                    key: 2,
                                    value: "2",
                                },
                                {
                                    id: 6,
                                    key: 1,
                                    value: "1",
                                },
                            ]}
                            defaultValue={updateData.displayOrder.toString()}
                            border={false}
                            handleChange={(e: any, text, value) => {
                                let newValue = Number(value);
                                newValue &&
                                    setUpdateData({
                                        ...updateData,
                                        ...{
                                            displayOrder: newValue,
                                            edit: true,
                                        },
                                    });
                            }}
                            isDisabled={!isApphome}
                        />
                    </div>
                    <span className="message">
                        *구분 ‘공지 - 홈노출’ 선택 시 노출순서 선택이 가능합니다{" "}
                    </span>
                </li>
                <li>
                    <Input
                        label="제목"
                        placeholder="제목 입력"
                        type="text"
                        size="medium"
                        handleChange={(e) => {
                            const { value } = e.target;
                            const { errMsg } = validChecker("spacebar", value);
                            errMsg ||
                                setUpdateData({
                                    ...updateData,
                                    ...{ title: value, edit: true },
                                });
                            setPageErrorMessage(errMsg);
                        }}
                        isDisabled={false}
                        errorMessage={""}
                        name="title"
                        value={updateData.title || ""}
                    />
                </li>
            </ul>
            <EditorBox
                handleChange={(value: string) => {
                    if (initData.contents !== value) {
                        const { errMsg } = validChecker(
                            "spacebar",
                            ConvertHtmlToString(value),
                        );
                        errMsg ||
                            setUpdateData({
                                ...updateData,
                                ...{ contents: value, edit: true },
                            });
                        setPageErrorMessage(errMsg);
                    }
                }}
                defaultText={initData.contents ?? ""}
            />
            <div className="buttonBox">
                <p>{pageErrorMessage}</p>
                <Button
                    label={isUpdate ? "수정" : "등록"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={!edit}
                    handleClick={handleClick}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
            <ConfirmModal
                children={isUpdate ? "수정하시겠습니까?" : "등록하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={isUpdate ? handleUpdateSubmit : handleCreateSubmit}
                handleCancel={handleCancel}
                buttonText={isUpdate ? "수정" : "등록"}
            />
        </StyledFormDiv>
    );
}

const StyledFormDiv = styled.div`
    width: 100%;
    padding: 40px;
    & > div:first-of-type {
        margin-bottom: 40px;
    }
    & > ul {
        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 28px;
            & > span {
                display: block;
                width: 120px;
            }
            & > .message {
                margin-left: 5px;
                width: 300px;
                font-weight: 400;
                font-size: 6px;
                color: #ff0000;
            }
            select {
                border-radius: 10;
                border: 0.5px solid ${Common.colors.gray200};
                color: black;
            }
        }
    }
    & > button:last-of-type {
        margin-left: auto;
        margin-top: 28px;
    }
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;
